import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';
import HBTextField from '../../../../UI/FormInputs/textFeild/HBTextField';
import HBTapRadioButtons from '../../../../UI/FormInputs/radioButtonField/HBTabRadioButton';
import Grid from '@mui/material/Grid2';
import time from '../../../../data/time.json';
import Button from '@mui/material/Button';
import CommonHeaderText from '../../../packages/common-header-text';
import HorizontalDateRange from '../../../packages/horizontal-date-range';
import { useForm } from '../useForm';
import { connect } from 'react-redux';
import { callEnquiryFormApi, getCountries } from '../../../../redux/actions/main';
import merge from 'lodash/merge';
import axios from 'axios';
import { MYACCOUNT_HOMEBAZAAR } from '../../../../config';
import CircularProgress from '@mui/material/CircularProgress';
import useUserInfo from '../../../packages/use-user-info';
import useUtmParams from '../../../../Hooks/useUtmParams';
import UtmParamsInput from '../UtmParamsInput';
import PopupDialogBox from '../../../packages/popup-dialog-box';
import useDisableBodyScroll from '../../../packages/use-disable-body-scroll';
import LeadOptForm from '../LeadOtpForm';
import { contactedSeenPropertyApicall } from '../../../../redux/actions/propertyDetails';
import breakpoints from '../../../../data/breakpoints.json';
import Variables from 'styles/variables.module.scss';
import Link from 'next/link';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  formWarpper: `${PREFIX}-formWarpper`,
  formWarpper1: `${PREFIX}-formWarpper1`,
  formSubmit: `${PREFIX}-formSubmit`,
  pdmain: `${PREFIX}-pdmain`,
  noRightBorderRadius: `${PREFIX}-noRightBorderRadius`,
  noLeftBorder: `${PREFIX}-noLeftBorder`,
  selectHeight: `${PREFIX}-selectHeight`,
  headingtitle: `${PREFIX}-headingtitle`,
  textInputSelect: `${PREFIX}-textInputSelect`,
  pherror: `${PREFIX}-pherror`,
  homeLoanCheckBox: `${PREFIX}-homeLoanCheckBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '5px',
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid #D3E0ED',
    [theme.breakpoints.down(breakpoints.sm)]: {
      borderRadius: '10px',
      border: 'none',
      padding: '6px',
    },
    '& .MuiTypography-root': {
      '& img': {
        marginRight: '7px',
      },
    },
    '& h4': {
      fontFamily: 'Open Sans!important',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: '7px',
      },
    },
    '& .MuiAutocomplete-input': {
      minWidth: '38px !important',
    },
  },

  [`& .${classes.formWarpper}`]: {
    height: 'auto !important',
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
    paddingTop: '0px',
    [theme.breakpoints.down(breakpoints.lgm)]: {
      height: '70vh !important',
      overflowY: 'scroll !important',
      overflowX: 'hidden !important',
      paddingTop: '0px',
    },

    [theme.breakpoints.down(breakpoints.xlx)]: {
      height: 'calc(80vh - 80px ) !important',
      overflowY: 'scroll !important',
      overflowX: 'hidden',
      paddingTop: '0px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      height: 'calc(88vh - 80px ) !important',
      overflowY: 'scroll !important',
      overflowX: 'hidden',
      paddingTop: '0px',
      backgroundColor: '#fff',
    },
  },

  [`& .${classes.formWarpper1}`]: {
    height: 'auto !important',
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
    paddingTop: '0px',
    [theme.breakpoints.down(breakpoints.lgm)]: {
      height: '70vh !important',
      overflowY: 'scroll !important',
      overflowX: 'hidden !important',
      paddingTop: '0px',
    },

    [theme.breakpoints.down(breakpoints.xlx)]: {
      height: 'calc(80vh - 80px ) !important',
      overflowY: 'scroll !important',
      overflowX: 'hidden',
      paddingTop: '0px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      height: 'auto !important',
      overflowX: 'hidden',
      paddingTop: '0px',
      backgroundColor: '#fff',
    },
  },

  [`& .${classes.formSubmit}`]: {
    width: '100%',
    backgroundColor: '#0056B2',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    // transition: 'all 0.3s ease-in-out',
    height: '50px',
    marginTop: '16px',
    marginBottom: '20px',
    borderRadius: '7px!important',
    font: 'normal normal bold 18px/24px Open Sans',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '7px',
      height: '50px',
      font: 'normal normal bold 20px/27px Open Sans',
      marginTop: '20px',
      marginBottom: '20px',
    },
  },

  [`& .${classes.pdmain}`]: {
    padding: '0px 25px 0px 30px',
    [theme.breakpoints.down(breakpoints.lg)]: {
      padding: '0px 18px 10px 21px',
    },
    [theme.breakpoints.down(breakpoints.smm)]: {
      padding: '0px 22px 10px 25px',
    },
  },

  [`& .${classes.noRightBorderRadius}`]: {
    '& .MuiInputLabel-shrink': {
      fontWeight: '400!important',
    },
    '& label': {
      fontWeight: '400!important',
    },
    '& .MuiFilledInput-inputMarginDense': {
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px',
      borderRight: 'none',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      fontWeight: '700!important',
    },
    '& .MuiSelect-iconFilled': {
      right: '40%',
      top: '50%!important',
    },
    '& .MuiSvgIcon-root': {
      top: '45%!important',
    },
  },

  [`& .${classes.noLeftBorder}`]: {
    '& .MuiFilledInput-inputMarginDense': {
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
    },
  },

  [`& .${classes.selectHeight}`]: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '15px',
      height: '42px',
      color: '#333333',
    },
  },

  [`& .${classes.headingtitle}`]: {
    color: Variables.darkColor,
    fontSize: '16px',
    fontFamily: 'Open Sans!important',
    fontWeight: '700',
    textAlign: 'center',
    margin: '10px 0 17px 0',
    lineHeight: '22px',
    letterSpacing: '0px',
    [theme.breakpoints.down(breakpoints.smm)]: {
      width: '100%',
      padding: '0px',
      fontSize: '13px',
      fontWeight: '700',
      color: Variables.darkColor,
      lineHeight: '18px',
    },
  },

  [`& .${classes.textInputSelect}`]: {
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      top: '1px',
    },
  },

  [`& .${classes.pherror}`]: {
    display: 'flex',
    alignItems: 'center',
    color: '#f44336',
    marginBottom: '7px',
    fontSize: '13px',
    fontFamily: 'Open Sans, sans-serif !important',
    '& img': {
      marginRight: '7px',
    },
  },

  [`& .${classes.homeLoanCheckBox}`]: {
    color: '#171717',
    font: 'normal normal normal 14px/19px Open Sans',
    marginLeft: '2px',
    // marginTop: '-18px',
    display: 'flex',
  }
}));

const tabRadioItems = [
  { name: 'Site Visit', image: '/static/images/builder-page-image.svg', styles: { objectFit: 'cover', objectPosition: '-248px 6px', width: '36px', height: '38px' } },
  { name: 'Virtual Visit', image: '/static/images/builder-page-image.svg', styles: { objectFit: 'cover', objectPosition: '-282px 6px', width: '36px', height: '38px' } },
];

const ScheduleTourForm = props => {
  const ENQUIREDFOR = 'Schedule Tour';
  const {
    root_style,
    root_class,
    heading,
    property_id,
    projects = [],
    buttons,
    module_type,
    hideHomeLoanCheck,
    // setOtpForm,
    // setOpenMobPopup,
    showHeading = true,
    popupOpen = false,
    value,
  } = props;


  const [Country, setCountry] = useState('India|+91');
  const [CountryCode, setCountryCode] = useState('+91');
  const handleChangeNew = event => {
    let countryVal = event.target.value;
    setCountry(countryVal);
    setCountryCode(countryVal.split('|')[1]);
  };

  const [success, setSuccess] = useState({ msg: '', class: '' });
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');
  const [date, setDate] = useState('');
  const [ip_address] = useState('1.1.1.1');
  const [button_disabled, setButtonDisabled] = useState(false);
  const [form_loading, setFormLoading] = useState(false);
  const [user] = useUserInfo();
  const utmParams = useUtmParams();
  const [openPopup, setOpenPopup] = useState(popupOpen);
  const [termsCheck, setTermsCheck] = useState(false);
  const [homeLoanCheck, setHomeLoanCheck] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState(false);
  const [in_person, setInPerson] = useState(true);
  const [, setVirtualVisit] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const isLogin = !(
      user === null ||
      user === undefined ||
      Object.values(user).length == 0
    );
    setIsUser(isLogin);
    if (isLogin) {
      setValues({
        name: user?.name ?? '',
        phone: user?.phone ?? '',
        email: user?.email ?? '',
      });
      setCountryCode(user.country_code ?? '+91');
      let countryObject = countries.find(
        countryItem => countryItem.countryCodevalue === user?.country_code
      );
      if (!countryObject) {
        countryObject = {
          countryCodelabel: user?.country_code,
          countryCodevalue: user?.country_code,
          countryName: 'India',
          countryIsoCode: 'IN',
        };
      }
      setCountry(
        `${countryObject.countryName}|${countryObject?.countryCodevalue}`
      );
    }
  }, [user]);
  const toggleInPerson = name => {
    if (name === 'Virtual Visit') {
      setVirtualVisit(true);
      setInPerson(false);
    } else if (name === 'Site Visit') {
      setInPerson(true);
      setVirtualVisit(false);
    }
  };
  const initialFValues = {
    name: '',
    phone: '',
    email: '',
    country_code: '',
    country: '',
    visittime: '',
    visitdate: '',
  };

  if (projects.length) {
    initialFValues.project_id = '';
  }
  useDisableBodyScroll(openPopup);

  function closeModal() {
    setOpenPopup(!openPopup);
  }
  const handleCallback = childData => {
    setSuccess({ msg: childData, class: 'thankyou-text' });
  };
  let logged_out = !isUser;
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? (
        fieldValues.name.length <= 25 ? (
          /^[a-zA-Z ]*$/.test(fieldValues.name) ? (
            ''
          ) : (
              (<Root style={{
                alignItems: 'center',
                display: 'flex'
              }}>
              <img src="/static/images/error-form.svg" alt="error-form"></img>Please enter a valid name.
            </Root>)
          )
        ) : (
          <>
            <img src="/static/images/error-form.svg" alt="error-form"></img>
            The name must be 25 characters or less.
          </>
        )
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img>
          Please enter a name.
        </>
      );

    if ('phone' in fieldValues) {
      if (CountryCode === '+91') {
        temp.phone = fieldValues.phone ? (
          /^\d*$/.test(fieldValues.phone) && fieldValues.phone.length === 10 ? (
            ''
          ) : (
            <>
              <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
              Please enter valid phone number
            </>
          )
        ) : (
          <>
            <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
            Please enter phone number.
          </>
        );
      } else if (CountryCode !== '+91') {
        temp.phone = fieldValues.phone ? (
          /^[0-9]*$/.test(fieldValues.phone) && fieldValues.phone.length > 10
        ) : (
          <>
            <img src="/static/images/error-form.svg" alt="error-form" /> Please
            enter phone number.
          </>
        );
      }
    }

    if ('email' in fieldValues)
      temp.email = fieldValues.email ? (
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fieldValues.email) ? (
          ''
        ) : (
          <>
            <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
            Please enter valid email
          </>
        )
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img>Please
          enter email id.
        </>
      );

    if ('visittime' in fieldValues)
      temp.visittime = fieldValues.visittime ? (
        ''
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img> {''}
          Please select the time slot
        </>
      );

    if ('visitdate' in fieldValues)
      temp.visitdate = fieldValues.visitdate ? (
        ''
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img>
          <span style={{ marginRight: '10px' }}>Please select date.</span>
        </>
      );

    if ('project_id' in fieldValues && projects.length)
      temp.project_id = fieldValues.project_id ? (
        ''
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img>Please
          select a project.
        </>
      );

    setErrors({
      ...temp,
    });

    setButtonDisabled(false);
    if (fieldValues == values) return Object.values(temp).every(x => x == '');
  };
  const contactedSeenPropertyApiCall = (
    module_type_contacted,
    user_contacted,
    property_id_contacted
  ) => {
    props.contactedSeenPropertyApicall({
      module_type: module_type_contacted,
      user_id: user_contacted?.id,
      property_id: property_id_contacted,
      category: 'contacted',
    });
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const setMyToken = () => {
    axios.get(
      `${MYACCOUNT_HOMEBAZAAR}ajax_login?goTopage=${btoa(
        values.email
      )}&comeFrompage=${btoa(values.phone)}`,
      { withCredentials: true }
    );
  };
  const validation = e => {
    if (termsCheck) {
      setCheckBoxErr(false);
      handleSubmit(e);
    } else {
      setCheckBoxErr(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let country = Country.split('|');
    merge(values, { url: url });
    merge(values, { type: 1 });
    property_id && merge(values, { project_id: property_id });
    merge(values, { country: country[0] });
    merge(values, { country_code: CountryCode });
    // merge(values, { country_code: country[1] });
    merge(values, { visitdate: date });
    merge(values, { module_type: module_type });
    merge(values, {
      tourinfn: in_person ? 'Tour in Person' : 'Tour Via Video Chat',
    });
    merge(values, { gclid: utmParams.gclid ?? '' });
    merge(values, { fbclid: utmParams.fbclid ?? '' });
    merge(values, {
      utm_source: utmParams.utm_source ?? '',
    });
    merge(values, {
      utm_medium: utmParams.utm_medium ?? '',
    });
    merge(values, {
      utm_campaign: utmParams.utm_campaign ?? '',
    });
    merge(values, {
      utm_term: utmParams.utm_term ?? '',
    });
    merge(values, { ip: ip_address });
    merge(values, { logged_in: !logged_out });
    merge(values, { applied_homeloan: homeLoanCheck === true ? 1 : 0 });
    merge(values, { enquiredfor: ENQUIREDFOR });
    if (validate()) {
      setButtonDisabled(true);
      setFormLoading(true);
      setError('');
      setSuccess({ msg: '', class: '' });
      await callEnquiryFormApi({
        data: values,
        onSuccess: (response) => {
          if (response.status === 200) {
            if (logged_out) {
              setSuccess({ msg: response.data.message, class: 'otp-text' });
              setOpenPopup(true);
            } else {
              setSuccess({
                msg: response.data.message,
                class: 'thankyou-text',
              });
              contactedSeenPropertyApiCall(
                module_type,
                response?.data?.user || {},
                property_id
              );
            }
    
            if (country[1] !== '+91') {
              setMyToken();
            }
          } else if (response.status === 202) {
            setError(response.data.email);
            setError(response.data.message);
          }
    
          setFormLoading(false);
        },
        onError: () => {
          setError('Something went wrong...');
          setFormLoading(false);
        },
      });
      // await axios
      //   .post(API_ROOT + ENQUIRY_FORM_SUBMIT_API, DataHelper(values), { 
      //     headers: headers,
      //   })
      //   .then(async response => {
      //     if (response.status == 200) {
      //       if (logged_out) {
      //         setSuccess({ msg: response.data.message, class: 'otp-text' });
      //       } else {
      //         setSuccess({
      //           msg: response.data.message,
      //           class: 'thankyou-text',
      //         });
      //       }
      //       if (country[1] !== '+91') {
      //         setMyToken();
      //       }
      //       if (logged_out) setOpenPopup(true);
      //       else {
      //         contactedSeenPropertyApiCall(
      //           module_type,
      //           response?.data?.user || {},
      //           property_id
      //         );
      //       }
      //     } else if (response.status == 202) {
      //       setError(response.data.email);
      //       setError(response.data.message);
      //     }
      //     setFormLoading(false);
      //   })
      //   .catch(() => {
      //     setError('Something went wrong...');
      //     setFormLoading(false);
      //   });
    }
  };
  const { countries } = props.countriesApiData;

  useEffect(() => {
    setUrl(window.location.href);
    let mounted = false;
    if (!mounted) {
      if (countries.length == 0) {
        props.getCountries();
      }
    }
  }, []);

  useEffect(() => {
    values.name = user?.name;
    values.phone = user?.phone;
    values.email = user?.email;
    values.country = user?.country;
    values.country_code = user?.country_code;
    setValues(values);
  }, [user]);

  const onInput = e => {
    if (CountryCode === '+91')
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, 10);
  };

  useEffect(() => {
    if (projects.length) {
      setValues({ ...initialFValues, project_id: '' });
    }
  }, [projects?.length]);

  return (<Root>
    <div
      className={ClassNames([classes.root, root_class])}
      style={root_style}>
      <form
        onSubmit={e => e.preventDefault()}
        // className={classes.formWarpper}
        className={ClassNames([
          value == 'mobPropertypage'
            ? classes.formWarpper1
            : classes.formWarpper,
        ])}
        style={{
          height: 'auto',
        }}>
        <div className={classes.pdmain}>
          {showHeading ? (
            heading ? (
              <h4 className={classes.headingtitle}>
                {`Schedule Site Visit to ${heading}`}
              </h4>
            ) : (
              <h4 className={classes.headingtitle}>
                {`Schedule Site Visit Now`}
              </h4>
            )
          ) : (
            <></>
          )}
          <div>
            <HBTextField
              name="name"
              id="name"
              label="Name"
              isDisabled={isUser}
              // isDisabled={user && user?.name ? true : false}
              style={{ width: '100%' }}
              value={values.name}
              onChange={handleInputChange}
              // error={errors.name}
              helperText={errors.name}
              variant="filled"
              inputProps={{ maxLength: 25 }}
            />
            <span className={classes.pherror}>{errors.name}</span>
          </div>

          <Grid container justifyContent="flex-start" alignitems="center">
            <Grid size={5}>
              <div>
                <HBTextField
                  className={classes.noRightBorderRadius}
                  noRightBorderRadius={true}
                  data-cy={'country-input'}
                  id="country"
                  name="country"
                  label="Country Code"
                  InputLabelProps={{ shrink: Country ? true : false }}
                  style={{
                    width: '101%',
                    fontWeight: '400',
                    fontSize: '16px'
                  }}
                  // isDisabled={user && user?.name ? true : false}
                  isDisabled={isUser}
                  onChange={handleChangeNew}
                  select
                  value={CountryCode}
                  SelectProps={{
                    native: true,
                  }}
                  variant="filled">
                  <option
                    key={`stSelectedCountry-${CountryCode}`}
                    value={(user && user?.country_code) || CountryCode}
                    hidden>
                    {(user && user?.country_code) || CountryCode}
                  </option>
                  {countries.map(data => (
                    <option
                      key={`stCountry-${data.countryName}-${data.countryCodelabel}`}
                      value={`${data.countryName}|${data.countryCodelabel}`}>
                      {data.countryName}|({data.countryCodelabel})
                    </option>
                  ))}
                </HBTextField>
              </div>
            </Grid>

            <Grid size={7}>
              <HBTextField
                // className={classes.noLeftBorder}
                noLeftBorder={true}
                name="phone"
                id="phone"
                type="number"
                label="Phone"
                // isDisabled={user && user?.phone ? true : false}
                isDisabled={isUser}
                style={{ width: '100%', borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px', }}
                value={values.phone}
                onChange={handleInputChange}
                onInput={onInput}
                size="small"
                variant="filled"
              />
            </Grid>
            <span className={classes.pherror}>{errors.phone}</span>
          </Grid>

          <HBTextField
            name="email"
            id="email"
            disabled={user && user?.email ? true : false}
            label="Email"
            variant="filled"
            style={{ width: '100%' }}
            value={values.email}
            onChange={user && user?.email ? '' : handleInputChange}
            // error={errors.email}
            helperText={errors.email}
            size="small"
          />
          <span className={classes.pherror}>{errors.email}</span>
          <HBTapRadioButtons
            data={tabRadioItems}
            onClick={toggleInPerson}
            defaultValue="Site Visit"
          />
            <Grid
              container
              justifyContent="flex-start"
              alignitems="center"
              sx={{ marginTop: '10px' }}>
              <Grid size={12}>
                <HorizontalDateRange
                  setDate={setDate}
                  start_date={new Date().addDays(1)}
                  end_date={new Date().addDays(11)}
                />
              </Grid>
              {errors.visitdate ? (
                <CommonHeaderText
                  text={errors.visitdate}
                  variant="h4"
                  style={{
                    color: '#E00000',
                    fontSize: '13px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    position: 'relative',
                    top: '-5px',
                  }}
                />
              ) : null}
            </Grid>

          <div className={classes.textInputSelect}>
            <HBTextField
              style={{ width: '100%', marginTop: '5px' }}
              select
              SelectProps={{
                native: true,
              }}
              className={classes.selectHeight}
              variant="filled"
              name="visittime"
              label={
                values.visittime === '' || values.visittime === 'Choose Time'
                  ? ''
                  : 'Time'
              }
              value={values.visittime}
              onChange={handleInputChange}
            // error={errors.visittime}
            >
              {/* <option value="">Choose Time</option> */}
              {time.map(option => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </HBTextField>
            <span className={classes.pherror}>{errors.visittime}</span>
          </div>
          {projects.length ? (
            <div className={classes.textInputSelect}>
              <HBTextField
                style={{
                  width: '100%',
                }}
                select
                value={values.project_id}
                SelectProps={{
                  native: true,
                }}
                className={classes.selectHeight}
                variant="filled"
                name="project_id"
                label={
                  values.project_id === '' ||
                    values.project_id === 'Select Project'
                    ? ''
                    : 'Project'
                }
                onChange={handleInputChange}
              // error={errors.project_id}
              >
                <option value="">Select Project</option>
                {projects.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </HBTextField>
              <span className={classes.pherror}>{errors.project_id}</span>
            </div>
          ) : (
            ''
          )}
          <UtmParamsInput />
          <div className={classes.textInputSelect}>
            {/* <FormControlLabel
            value="end"
            control={<Checkbox required={true} color="primary" />}
            label="I accept Terms and Conditions"
            labelPlacement="end"
            variant="h4"
            style={{
              color: '#171717',
              font: 'normal normal normal 14px/19px Open Sans',
              letterSpacing: '0px',
            }}
          /> */}
            <div
              style={{
                color: '#171717',
                font: 'normal normal normal 14px/19px Open Sans',
                marginLeft: '2px',
                marginBottom: '15px',
                display: 'flex',
                marginTop: '1.3rem',
              }}>
              {!termsCheck && (
                <img
                  src="/static/images/checkbox.svg"
                  width="16px"
                  height="19px"
                  alt="checkbox"
                  onClick={() => {
                    setTermsCheck(true);
                    setCheckBoxErr(false);
                  }}
                />
              )}
              {termsCheck && (
                <img
                  src="/static/images/icon-feather-check-square.svg"
                  width="16px"
                  height="19px"
                  alt="icon-feather-check-square"
                  onClick={() => setTermsCheck(false)}
                  required={true}
                />
              )}
              <div
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                }}>
                I accept{' '}
                <Link
                  href={'/privacy-policy'}
                  target="_blank"
                  style={{ color: '#0056b2' }}>
                  {' '}
                  &nbsp;T&C and Privacy Policy{' '}
                </Link>
              </div>
            </div>
            {hideHomeLoanCheck ||
              (module_type === 'buy' && (
                <div className={classes.homeLoanCheckBox}>
                  <img
                    src={
                      homeLoanCheck
                        ? '/static/images/icon-feather-check-square.svg'
                        : '/static/images/checkbox.svg'
                    }
                    alt="chekcbox"
                    onClick={() => {
                      if (homeLoanCheck) {
                        setHomeLoanCheck(false);
                      } else {
                        setHomeLoanCheck(true);
                        setCheckBoxErr(false);
                      }
                    }}
                  />
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'flex',
                    }}>
                    I am interested in Home Loans
                  </div>
                </div>
              ))}
          </div>

          {success.msg != '' && (
            <h5 className={success.class}>{success.msg}</h5>
          )}

          {error != '' && <h5 className="error-text-upd">{error}</h5>}

          {checkBoxErr != '' && (
            <h5 className="error-text-upd">
              <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
              {'Please select the T&C and Privacy Policy'}
            </h5>
          )}

          {form_loading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <CircularProgress />
            </div>
          )}

          {success === 'Thank You for Contacting Us!!' &&
            openPopup &&
            setOpenPopup(false)}
          {success.msg === 'OTP created Successfully' && (
            <PopupDialogBox
              root_class="custom-modal"
              open={openPopup}
              close={closeModal}
              modal_content={
                <LeadOptForm
                  setMyToken={setMyToken}
                  Country={Country}
                  values={values}
                  url={url}
                  property_id={property_id}
                  enquiredFor={ENQUIREDFOR}
                  parentCallback={handleCallback}
                  setOpenPopup={setOpenPopup}
                />
              }
            />
          )}

          {buttons ? (
            <>
              <Grid
                container
                spcing={3}
                alignItems="center"
                justify="space-between"
                size={12}>
                <Button
                  data-cy="get-quote-submit"
                  disabled={button_disabled}
                  className={classes.formSubmit}
                  variant="contained"
                  onClick={validation}>
                  Arrange Visit
                </Button>
              </Grid>
            </>
          ) : (
            <Button
              data-cy="get-quote-submit"
              disabled={button_disabled}
              className={classes.formSubmit}
              variant="contained"
              onClick={validation}>
              Arrange Visit
            </Button>
          )}
        </div>
      </form>
    </div>
  </Root>);
};

const mapStateToProps = state => ({
  countriesApiData: state.countriesReducer,
  properycontactedseenApiData: state.cotactedseenpropertyReducer,
});

const mapDispatchToProps = {
  getCountries,
  contactedSeenPropertyApicall,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTourForm);
